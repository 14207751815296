import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Sobre nós</h2>
          <h4 className={classes.description}>
            A Impacta Home tem como objetivo fazer parte dos momentos mais especiais e inspiradores
            da vida das pessoas.
          <br />
            <br />
            Acreditamos no grande valor e importância que a mesa possui na sociedade. É na mesa que
            compartilhamos histórias, criamos vínculos e vivenciamos momentos inesquecíveis, e a
            prática da mesa posta torna tudo mais prazeroso, e os que estão a volta sentem-se
            honrados com esse ato, pois recebem o carinho e cuidado demonstrado em cada detalhe.
          <br />
            <br />
            Nossos produtos são produzidos com todo esse sentimento que a mesa posta desperta nas
            pessoas, as peças são feitas artesanalmente com todo o amor. Queremos proporcionar uma
            mesa mais bonita, charmosa, elegante e agradável. Selecionamos cada matéria prima com
            todo o cuidado e sutileza, para que o produto final chegue aos nossos clientes e traga
            uma grande satisfação.
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
