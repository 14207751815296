import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import tableware from "assets/img/products/tableware.jpeg";
import tablewareTwo from "assets/img/products/tableware_2.jpeg";
import napkin from "assets/img/products/napkin.jpeg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Nossos produtos</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={tableware} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Jogos Americanos
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                Nossos jogos são feitos de um tecido com acabamento <i>Easy Clean</i>, que torna o tecido mais 
                resistente ao contato com líquidos e manchas. Por serem dupla face permitem diversas 
                combinações, promovendo maior criatividade no momento da decoração. Procuramos trabalhar com estampas clássicas, românticas e 
                modernas, para que melhor possamos agradar cada estilo e gosto dos nossos clientes. Os jogos 
                americanos da Impacta Home é o produto ideal para quem busca elegância, unida a qualidade, 
                praticidade e resistência para sua mesa.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={napkin} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Guardanapos
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                Os guardanapos de tecido complementam os jogos americanos deixando a mesa posta com muito 
                mais charme possibilitando belas decorações. Estampado ou liso, os guardanapos se adaptam 
                facilmente ao estilo da sua louça ou roupa de mesa, e ficam incríveis com acessórios como 
                os porta guardanapos ou com técnicas de dobraduras. Além de ser um produto feito para ser 
                reutilizado, sendo assim mais sustentável. Feito artesanalmente, nossos guardanapos 
                possuem acabamento em canto mitrado e barra mais larga, valorizando a peça.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={tablewareTwo} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Porta Guardanapos
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                Podemos dizer que os porta guardanapos são a “cereja do bolo”. Lindos e práticos, envolve 
                o guardanapo e deixam sua mesa mais especial, cativando todos os olhares. Nossas opções 
                são feitas artesanalmente e produzidas com todo o carinho, um item que chama muita 
                atenção, merece todo esse cuidado. As matérias-primas são bem selecionadas, como os de 
                flores artificiais, prezamos em adquirir flores com ótima qualidade. A finalização da sua 
                mesa posta com nossos porta guardanapos, ficará linda e charmosa.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
